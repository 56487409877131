import React, { FC } from 'react'

interface IFacebookIconLinkProps {
  src: any
  className?: string
}

const FacebookIconLink: FC<IFacebookIconLinkProps> = ({ src, className }) => (
  <a href="https://www.facebook.com/theifab/" target="_blank" rel="noreferrer">
    <img src={src} alt="Facebook" className={className} />
  </a>
)

export default FacebookIconLink
