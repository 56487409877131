import React, { FC } from 'react'

interface ITwitterIconLinkProps {
  src: any
  className?: string
}

const TwitterIconLink: FC<ITwitterIconLinkProps> = ({ src, className }) => (
  <a href="https://twitter.com/TheIFAB/" target="_blank" rel="noreferrer">
    <img src={src} alt="Twitter icon" className={className} />
  </a>
)

export default TwitterIconLink
