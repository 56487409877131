import React, { CSSProperties, FC } from 'react'

interface ILinkedinIconLinkProps {
  src: any
  className?: string
  style: CSSProperties
}

const LinkedinIconLink: FC<ILinkedinIconLinkProps> = ({
  src,
  className,
  style,
}) => (
  <a
    href="https://ch.linkedin.com/company/the-ifab"
    target="_blank"
    rel="noreferrer"
  >
    <img src={src} alt="Linkedin" className={className} style={style} />
  </a>
)

export default LinkedinIconLink
