import React, { useRef } from 'react'
import logoSvg from 'src/images/logo/logo-with-federations.svg'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'
import JoinUsIconsMobile from 'src/components/icons/JoinUsIconsMobile'

const Footer = () => {
  const footerRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation('menu')

  const showCookiePreferenceCenter = typeof window === 'undefined'
    ? null 
    : window?.OneTrust?.ToggleInfoDisplay

  return (
    <footer
      className="bg-almostBlackBlue text-white relative z-10"
      ref={footerRef}
      id="footer"
    >
      <div className="pb-14 pt-20 lg:py-28 text-white lg:border-t lg:border-navyBlueGray flex flex-col">
        <div className="container flex justify-center items-center">
          <img
            src={logoSvg}
            alt="IFAB logo with federations"
            className="w-28 lg:w-38"
            width="152"
            height="87"
          />
          <div className="border-l-2 borer-white ml-4 pl-4 lg:ml-4 lg:pl-4 flex items-center justify-center h-17 lg:h-24">
            <p className="text-white uppercase text-sm lg:text-base">
              <strong>
                The international
                <br />
                football association
                <br />
                board&nbsp;
              </strong>
              / guardians of
              <br />
              the laws of the game
            </p>
          </div>
        </div>
        <JoinUsIconsMobile className="lg:hidden mt-14" />
      </div>

      <div className="py-4 px-4 mx-auto text-center text-navyBlueGray text-sm">
        <span className="inline-block">
          The IFAB {new Date().getFullYear()}®
          <span className="hidden xl:inline"> All Rights Reserved</span>
          <span className="mx-2">|</span>
        </span>
        <span className="inline-block">
          <Link to="/terms-of-service">{t('Terms of service')}</Link>
          <span className="mx-2">|</span>
        </span>
        <span className="inline-block">
          <Link to="/privacy-policy">{t('Privacy policy')}</Link>
          <span className="mx-2">|</span>
        </span>
        <span className="inline-block">
          <Link to="/cookies">{t('Cookie policy')}</Link>
          <span className="mx-2">|</span>
        </span>
        <span className="inline-block">
          {/* Enter the same button label in OneTrust dashboard */}
          <button 
            id="ot-sdk-btn"
            className="ot-sdk-show-settings"
            onClick={showCookiePreferenceCenter}
          >
            { t('Preference Center') }
          </button>
          <span className="mx-2">|</span>
        </span>
        <Link to="/contact">{t('Contact')}</Link>
      </div>
    </footer>
  )
}

export default Footer
