import React from 'react'
import { Link } from 'gatsby-plugin-react-i18next'
import LogoSvg from 'src/images/logo/logo-image.svg'

const Logo = () => (
  <Link to="/">
    <img src={LogoSvg} alt="IFAB" className="h-8" />
  </Link>
)

export default Logo
