import React, { FC } from 'react'
import TwitterBoxIcon from 'src/components/icons/TwitterBox.svg'
import FacebookBoxIcon from 'src/components/icons/FacebookBox.svg'
import LinkedinIcon from 'src/components/icons/Linkedin.png'
import FacebookIconLink from 'src/components/icons/FacebookIconLink'
import TwitterIconLink from 'src/components/icons/TwitterIconLink'
import LinkedinIconLink from 'src/components/icons/LinkedinIconLink'

interface IJoinUsIconsMobileProps {
  className?: string
}

const JoinUsIconsMobile: FC<IJoinUsIconsMobileProps> = ({ className }) => (
  <div className={`flex justify-center ${className || ''}`}>
    <TwitterIconLink src={TwitterBoxIcon} className="w-9" />
    <FacebookIconLink src={FacebookBoxIcon} className="w-9 ml-4" />
    <LinkedinIconLink
      src={LinkedinIcon}
      className="ml-4"
      style={{ width: '41px' }}
    />
  </div>
)

export default JoinUsIconsMobile
